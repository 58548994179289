.rounded-cropper {
  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    border-radius: 50%;
    outline: 0;
  }
  .cropper-face {
    background-color: inherit !important;
  }
  .cropper-view-box {
    outline: inherit !important;
  }
}

.cropper-zoom {
  bottom: 16px;
  right: 16px;

  &__button--round {
    width: 32px;
    height: 32px;
    font-size: 24px;
    border: solid 1px #2f665c;
    color: #74818d;
    background-color: rgba(255, 255, 255, 0.9);
  }
}
