.OperatorDropdown {
  display: flex;

  .FilterToggle {
    height: 36px;
    background-color: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;

    &.isInvalid {
      border: 1px solid #f46a6a;
    }
  }

  .ListGroup {
    min-width: auto !important;
  }
}

.ItemsWrapper {
  max-height: 180px;
  overflow-y: scroll;
}

.DropdownMenu {
  width: 100%;
  min-width: auto;
}
