body .limit-dropdown {
  margin-right: 12px;

  &__toggle {
    width: 80px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #74788d;

    &:hover {
      border: 1px solid #556ee6 !important;
    }
  }

  &--menu {
    margin-bottom: 4px;
    min-width: 80px;
    width: 80px;
    box-shadow: 0 2px 4px rgba(100, 100, 100, 0.5);

    &__option {
      color: #495057;
    }
  }
}

body .first-sticky-column {
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 10px 0 10px rgba(100, 100, 100, 0.1);
  z-index: 10;

  min-width: 45px !important;
}

body .customer-sticky-header {
  position: sticky;
  top: 70px;
  z-index: 50;
  background-color: white;
}

body .second-sticky-column {
  background-color: white;
  position: sticky;
  z-index: 10;
  top: 0;
  left: 45px;
  box-shadow: 10px 0 10px rgba(100, 100, 100, 0.1);
}

body .before-last-sticky-column {
  background-color: white;
  position: sticky;
  top: 0;
  right: 75px;
  box-shadow: -10px 0 10px rgba(100, 100, 100, 0.1);
  z-index: 10;

  min-width: 80px !important;
}

body .last-sticky-column {
  background-color: white;
  position: sticky;
  top: 0;
  right: 0;
  box-shadow: 0 0 0 rgba(100, 100, 100, 0.1);
  z-index: 10;
  overflow: inherit;
  white-space: normal;
  text-overflow: unset;

  min-width: 75px !important;
}

body .last-sticky-column:has(section.force-z-index) {
  z-index: 20;
}

body .custom-horizontal-scrollbar {
  scroll-behavior: smooth;
  min-height: 250px;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background: #b2b4c3;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #74788d;
  }
}

.status-badge {
  font-size: 10px;
  border-radius: 4px;
  width: fit-content;
  padding: 0 4px;
}

.blue-badge {
  color: #50a5f1;
  background-color: rgba(80, 165, 241, 0.18);
}

.grey-badge {
  color: #495057;
  background: rgba(73, 80, 87, 0.18);
}

.red-badge {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}

.green-badge {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}

.orange-badge {
  color: #f1b44c;
  background-color: rgba(241, 180, 76, 0.18);
}

.filters-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
}

body .date-range-input {
  z-index: 11;

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__month-dropdown-container {
    margin-right: 13px;

    .react-datepicker__month-read-view--down-arrow {
      top: 4px;
    }

    .react-datepicker__month-read-view--selected-month {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #495057;
    }
  }

  .react-datepicker__year-dropdown-container {
    margin-left: 13px;

    .react-datepicker__year-read-view--down-arrow {
      top: 4px;
    }

    .react-datepicker__year-read-view--selected-year {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #495057;
    }
  }

  .react-datepicker__day-name {
    height: 20px;
    line-height: 20px;
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
    background: #556ee6;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    color: white;
  }

  .react-datepicker__day--in-range.react-datepicker__day--range-start {
    background: #556ee6;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    color: white;
  }

  .react-datepicker__day--in-range.react-datepicker__day--range-end {
    background: #556ee6;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    color: white;
  }

  .react-datepicker__day--in-range {
    width: 32px;
    color: #495057;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background-color: #ced4da;
  }

  .react-datepicker__day--in-selecting-range {
    width: 32.5px;
    color: #495057;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background-color: #ced4da;
  }

  .react-datepicker__month-read-view {
    visibility: visible !important;
  }

  .react-datepicker__year-read-view {
    visibility: visible !important;
  }
}

body .three-dot-dropdown {
  min-width: 190px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(100, 100, 100, 0.5);
  border-radius: 2px;
  padding: 8px 0;
  &__item {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 20px;
    color: #495057;
    padding: 5px 14px;
    white-space: unset;
  }
  &__icon {
    width: 16px;
    height: 16px;
    color: #495057;
  }
}

.results-search {
  min-width: 424px;
}

.result-filter {
  min-width: 374px;
}

.result-filter-responsible {
  width: 100%;
}

.template-modal {
  width: 440px;
  max-width: 440px;
}

.hl7-modal {
  width: 880px;
  max-width: 880px;

  .spinner {
    height: 150px;
  }

  .border-none {
    border: none;
    padding-bottom: 0;
  }

  .file-text {
    white-space: pre-line;
  }

  .nav-tabs-custom {
    width: 100%;
    flex-wrap: nowrap;

    .nav-item {
      width: 100%;
    }
  }
}

body .popup-table {
  min-height: unset;

  .table-spinner {
    width: 100%;
    min-width: 100%;
    padding-top: 15vh;
    height: 300px;
  }
}

body .popup-table-pdf {
  min-height: 500px;

  .table-spinner {
    width: 100%;
    min-width: 100%;
    padding-top: 15vh;
    height: 300px;
  }
}

body .pre-line {
  white-space: pre-line;
}

body .error-inner-max-height {
  max-height: 450px;
  background-color: #495057;
}

body .fade {
  transition: none !important;
}

body .error-result-tooltip {
  opacity: 1 !important;
  border-radius: 4px;
}

.templates-list {
  min-height: 196px;
  max-height: 196px;
  overflow-y: auto;

  &__spinner {
    margin-top: 40%;
  }

  &__item {
    padding: 5px 12px;

    &:hover {
      background-color: #deebff;
    }

    &__text {
      max-width: 80%;
    }
  }
}

.sidebar-templates {
  max-height: 50vh;
  overflow-y: auto;

  &__title {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
    color: #74788d;
    padding-left: 56px;
    margin-bottom: 4px;
    margin-top: 10px;
  }

  &__counter {
    color: #ffffff;
    background-color: #556ee6;
    border-radius: 10px;
    padding: 0 6px;
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
    position: absolute;
    right: 10px;
    top: 12px;

    &.active {
      background-color: #ffffff;
      color: #556ee6;
    }
  }
}

.customer-results-details-tabs {
  margin-top: 40px;
}
