.MobilePreviewContainer {
  position: relative;
  width: 280px;
  height: 552px;
  background-size: cover;
  overflow: hidden;
  padding: 105px 24px 78px 32px;

  .MobilePreviewBody {
    height: 100%;
    overflow: hidden;
    padding-right: 8px;
    cursor: grab;
    user-select: none;
  }
}

.MobilePreviewContainerDashboard {
  position: relative;
  width: 280px;
  height: 552px;
  background-size: cover;
  overflow: hidden;
  //padding: 53px 24px 78px 32px; // Old layout
  padding: 31px 24px 78px 35px; // New layout

  .MobilePreviewBody {
    height: 100%;
    overflow: hidden;
    padding-right: 8px;
    cursor: grab;
    user-select: none;
  }
}

.mobileServices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 8px;
  height: 50px;
  color: #97A5A9;
  padding-right: 8px;
}

.mobileService {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}