

.LogicalRelationWrapper {
  border-radius: 6px;
  border: 1px solid #CED4DA;
  width: 100%;
  padding: 1px;
  display: flex;
  height: 32px;
  background-color: white;

  .Button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #74788D;
    font-weight: 600;
    cursor: pointer;
    flex: 0 0 50%;

    &.Active {
      background: #556EE6;
      color: white;
    }
  }

}
