//
// projects.scss
//
.ui-customisation-accordion {
  .accordion-item {
    background-color: #FFF;
    border: none;

    .accordion-header {
      .accordion-button {
        font-size: 15px;
        font-weight: 600;
        color: #495057;
        background-color: #FFF;
        box-shadow: none;
        padding: 16px 0;
      }
    }
    .accordion-body {
      padding: 0 0 48px 0;
    }
  }
}

.mobile-services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Two columns
  gap: 1rem;
}

.mobile-services-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid #2456c5;
  border-radius: 3px;
  padding: 1rem;
  width: auto;
  height: auto;
  cursor: pointer;
  user-select: none; // Disable text selection
  background-color: #fff;
}