.day-marker {
  color: #97A5A9;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 1rem;
}

.fitness-type-wrapper {
  display: flex;
  gap: 1rem;

  .type-icon {
    width: 24px;
    height: 24px;
  }

  span {
    color: #97A5A9;
  }
}