@font-face {
  font-family: "Neue Montreal";
  src: url("./assets/fonts/NeueMontreal/NeueMontreal-Regular.otf");
}

.article-page {
  padding: 20px;
  background-color: #F8F8F8;

  * {
    max-width: 100%;
    font-family: "Neue Montreal", sans-serif;
  }

  h1, h2, h3, h4 {
    color: #062021;
    font-weight: 500;
  }

  p {
    color: #233B3B;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.social-item {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.instagram {
    background-color: #F00073;
  }
  &.tiktok {
    background-color: #072021;
  }
  &.facebook {
    background-color: #1977F2;
  }
  &.other {
    background-color: #F2F7FB;
  }
}

.grid-section {
  @media (max-width: 400px) {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 500px) {
  .article-page {
    padding: 10px;
  }
}

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid #3ECAE3;
}
.text-description {
  color: #657276;
}

