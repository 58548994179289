.CarouselItem {
  width: 100%;
  height: 105px;
  border-radius: 16px;
  overflow: hidden;

  &.Placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F2F2F2;
    color: #6A7187;
  }
}

.CustomIndicators {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;

  .IndicatorDot {
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.6s ease;

    &.Active {
      background-color: #3ECAE3;
    }

    &:hover {
      background-color: #fff;
    }
  }
}