.related-article {
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: 100px 1fr;
  width: 100%;
  column-gap: 10px;
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 12px;

  &__image {
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__info {

  }

  &__banner {
    grid-column-start: 2;

    display: flex;
    align-items: center;
    gap: 8px
  }
}