.ItemsWrapper {
  max-height: 180px;
  overflow-y: scroll;
}


.Slide {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #CED4DA;
  color: #CED4DA;
  padding: 7px 16px;
  font-weight: 500;
  font-size: 13px;

  &.Active {
    border-bottom: 1px solid #556ee6;
    color: #556ee6;
  }

  .ListGroup {
    border: 1px solid #CED4DA;
    border-radius: 4px;

    .ItemsWrapper {
      height: 200px;
      overflow-y: scroll;
    }

    .ServiceItem {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid #EFF2F7;
    }
  }

  .ArrowIcon {
    color: #74788D;
    background: transparent;
    border: 0;

    &__Left {
      transform: rotate(90deg);
    }

    &__Right {
      transform: rotate(-90deg);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
}