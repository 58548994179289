body .attribute {
  &__na-icon {
    background-color: #F2F2F2;
    border-radius: 20px;
    text-align: center;
    padding: 4px;
    max-width: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}