.ExerciseGroupContainer {
  position: relative;
}

.ExerciseGroupHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ListItem {
  position: relative;
  margin-top: 12px;

  &::marker {
    font-size: 0;
  }
}

.Index {
  position: absolute;
  top: 36px;
  left: -10px;
}

.ExerciseGroupTitle {
  position: relative;
  left: -10px;
  font-weight: 600;
}

.AdditionalFieldWrapper {
  display: flex;
  gap: 32px;
  padding-left: 40px;
  margin-bottom: 16px;
}
