.exercise-wrapper {
  background-color: #F2F7FB;
  border-radius: 16px;
  padding: 1rem;

  .exercise-header {
    border-bottom: 1px solid #DDE2E3;
    padding-bottom: 1rem;

    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
    }

    .description {
      margin-bottom: 20px;
    }
  }

  .exercise-list-wrapper {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.exercise-info {
  display: flex;
  font-size: 14px;

  div {
    padding: 0 0.5rem;
  }

  :first-child {
    border-right: 1px solid #DDE2E3;
    padding-left: 0;
  }
}