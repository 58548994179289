.exercise {
  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .media {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .media-container {
    video, img {
      border-radius: 16px;
      overflow: hidden;
    }
    .text-description {
      text-align: center;
      color: #657276;
      font-size: 10px;
    }
  }

  .instruction {
    display: flex;
    justify-content: center;
    color: #97A5A9;
    margin-bottom: 0.25rem;

    div {
      padding: 0 0.5rem;
    }

    .border {
      padding: 0;
      width: 3px;
      height: 20px;
      background-color: #DDE2E3;
    }
  }

  .rest {
    text-align: center;
  }
}